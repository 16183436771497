.hide {
  display: none !important;
}

.centered {
  text-align: center;
}

.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
}

@media (--media-sm-only) {
  .sr-only-mobile {
    width: 0px;
    height: 0px;
    position: absolute;
    overflow: hidden;
  }
}

.skip-link:focus-visible {
  height: auto;
  width: auto;
  left: 5rem;
  top: 5rem;
  z-index: var(--z-skiplinks);
}

.print-only {
  display: none;

  @media print {
    display: unset;
  }
}

/*
As a rule, only add such classes if they are used in several blocks; once or
twice isn't enough
*/
.grow-all-v {
  display: flex;
  flex-direction: column;

  > * {
    flex-grow: 1;
  }
}

.flex-v {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

/*
Soon!
:has(.grow-v) {
  display: flex;
  flex-direction: column;
}
.grow-v {
  flex: 1 0 auto;
}
*/
